<template>
  <div class="home-about-us">
    <div class="wrap">
      <UiHeading
        :h="'2'"
        :type="'E'"
        :text="'Company Information'"
        :subTitle="'会社情報'"
      />
<UiTable :tableList="TableList" class="table" />
<div class="about-us-box space-150">
      <div class="left">
        <div class="square">
          <img
           src="@/assets/RobecityInternational-hp-top-006.jpg"
          />
        </div>
      </div>
      <div class="right">
        <h3 style="color:var(--sub) ">代表者あいさつ</h3>
        <p>株式会社R.C.I.のホームページをご覧いただき、誠にありがとうございます。株式会社R.C.I.は、「人とのご縁」を大切にしています。私の人生を振り返ると、どんなときも人とのご縁に救われ、これまで歩んでくることができました。目の前のお客様を大切にする。お客様のご希望・ご依頼にそうために、全力で努力をする。これが私たちの使命です。いつもうまくいくとは限りませんが、最大の努力をすることをモットーとしています。そして、このような私たちの努力と思いによって、人とのご縁が広がり、関わるすべての人が幸せになっていくことを願っています。</p>
      <p class="ceo">川上 貴史</p>
      </div>


</div>


    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiTable from "@/components/Ui/Table.vue";
// import UiBox from '../Ui/Box.vue';

export default {
  name: "HomeAboutUs",
  components: {
    UiHeading,
    UiTable
    //  UiBox,
  },
  props: {},
  data() {
    return {
      TableList: [
        {
          id: 1,
          th: "会社名",
          td: "株式会社R.C.I.（アールシーアイ）<br><span class=small>旧社名：株式会社Robecity International<br class=sp-only><span class=small-sub>（ローブシティインターナショナル）</span></span>",
        },
        {
          id: 2,
          th: "代表者名",
          td: "川上 貴史",
        },
        {
          id: 3,
          th: "住所",
          td: "〒461-0005 愛知県名古屋市東区東桜1-10-36",
        },
        {
          id: 4,
          th: "電話番号",
          td: '058-337-2801（平日10時～17時）'
        },]};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.home-about-us{
  p{
    color: var(--black)!important;
}
.table{
  width: 70%;
  margin: 50px auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }

}
  }

  .about-us-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @media screen and (min-width:768px) and ( max-width:982px){
align-items: flex-start;
  }

  @media screen and (max-width: 767px){
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .left {
    width: 48%;
@media screen and (max-width: 767px){
  width: 100%;
}

    .square {
      background-color: var(--sub2);
      width: 100%;
      padding-top: 100%;
      position: relative;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 75%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
      }
    }
  }
  .right{
    width: 48%;
    @media screen and (max-width: 767px){
  width: 100%;
}
h3{
  @media screen and (max-width: 767px) {
    padding-top: 40px;
  }
}
p{
  padding-top: 20px;
}
.ceo{
text-align: right;
font-size: 18px;
font-family: "Times New Roman", "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", serif;
font-weight: bold;
}
  }
  }
</style>
