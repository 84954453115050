<template>
  <div class="layout-footer">
    <!-- <div class="tokuteisho" v-show="path !== `/tokuteisho/`">
      <router-link to="/tokuteisho/">特定商取引法に基づく表示</router-link>
    </div> -->
    <div class="footer" :class="{ 'footer-sp-bottom': path !== `/contact/` }">
      <div class="fotter-inner">
        <img
          alt="R.C.I.ロゴ"
          src="@/assets/logow.png"
          class="logo"
        />
        <!-- <svg
            id="レイヤー_1"
            data-name="レイヤー 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 460.62 58.79"
            width="300px"
            height="50px"
            class="logo"
          >
            <defs></defs>
            <path
              class="cls-1"
              d="M329.28,26.68c-10.4,0-18.83,7.58-18.83,16.93s8.43,16.92,18.83,16.92S348.1,53,348.1,43.61,339.68,26.68,329.28,26.68Zm0,27.4c-6.44,0-11.65-4.69-11.65-10.47s5.21-10.48,11.65-10.48,11.65,4.69,11.65,10.48S335.71,54.08,329.28,54.08Z"
              transform="translate(-1.96 -1.74)"
            />
            <rect
              class="cls-1"
              x="294.44"
              y="25.37"
              width="7.17"
              height="32.67"
            />
            <rect class="cls-1" y="25.37" width="7.17" height="32.67" />
            <polygon
              class="cls-1"
              points="41.55 47.33 22.85 25.37 15.68 25.37 15.68 58.05 22.85 58.05 22.85 34.43 41.55 58.05 48.72 58.05 48.72 25.37 41.55 25.37 41.55 47.33"
            />
            <polygon
              class="cls-1"
              points="203.37 47.33 184.68 25.37 177.5 25.37 177.5 58.05 184.68 58.05 184.68 34.43 203.37 58.05 210.54 58.05 210.54 25.37 203.37 25.37 203.37 47.33"
            />
            <polygon
              class="cls-1"
              points="378.2 47.33 359.5 25.37 352.33 25.37 352.33 58.05 359.5 58.05 359.5 34.43 378.2 58.05 385.37 58.05 385.37 25.37 378.2 25.37 378.2 47.33"
            />
            <path
              class="cls-1"
              d="M172.75,37.52c0-5.18-5.45-10.41-16.35-10.41H138.1V59.79h7.18V31.87H156.4c6.09,0,9.11,2.85,9.11,5.65s-3,5.69-9.11,5.69h-8.47v2.24l18.29,14.34h9.4L159.77,47.51C168.38,46.53,172.75,42.14,172.75,37.52Z"
              transform="translate(-1.96 -1.74)"
            />
            <polygon
              class="cls-1"
              points="129.04 30.09 128.96 25.37 98.36 25.37 98.36 58.05 129.04 58.05 129.04 53.33 105.69 53.33 105.69 44.07 128.38 44.07 128.46 39.3 105.69 39.3 105.69 30.09 129.04 30.09"
            />
            <polygon
              class="cls-1"
              points="230.13 25.64 213.9 58.05 221.08 58.05 232.58 34.97 244.17 58.05 251.35 58.05 235.12 25.64 230.13 25.64"
            />
            <polygon
              class="cls-1"
              points="405.15 25.51 388.92 57.91 396.1 57.91 407.6 34.83 407.6 34.83 419.19 57.91 426.37 57.91 410.14 25.51 405.15 25.51"
            />
            <polygon
              class="cls-1"
              points="77.13 25.37 69.95 25.37 69.95 25.37 53.38 25.37 53.38 30.09 69.95 30.09 69.95 58.05 77.13 58.05 77.13 30.09 93.7 30.09 93.7 25.37 77.13 25.37 77.13 25.37"
            />
            <polygon
              class="cls-1"
              points="437.08 53.33 437.08 25.37 429.91 25.37 429.91 53.33 429.91 58.05 437.08 58.05 460.62 58.05 460.62 53.33 437.08 53.33"
            />
            <polygon
              class="cls-1"
              points="271.92 25.37 264.74 25.37 264.74 25.37 248.17 25.37 248.17 30.09 264.74 30.09 264.74 58.05 271.92 58.05 271.92 30.09 288.49 30.09 288.49 25.37 271.92 25.37 271.92 25.37"
            />
            <path
              class="cls-1"
              d="M173.6,1.74c-4.63,0-8.39,2.44-8.39,5.46s3.76,5.47,8.39,5.47S182,10.22,182,7.2,178.24,1.74,173.6,1.74Zm0,8.85c-2.87,0-5.19-1.52-5.19-3.39s2.32-3.38,5.19-3.38,5.2,1.52,5.2,3.38S176.47,10.59,173.6,10.59Z"
              transform="translate(-1.96 -1.74)"
            />
            <rect
              class="cls-1"
              x="267.93"
              y="0.17"
              width="3.2"
              height="10.55"
            />
            <path
              class="cls-1"
              d="M156.25,5.29c0-1.67-2.43-3.36-7.29-3.36H140.8V12.48H144v-9h5c2.72,0,4.06.92,4.06,1.83S151.68,7.13,149,7.13h-3.77v.72l8.15,4.63h4.19l-7.07-4C154.3,8.2,156.25,6.78,156.25,5.29Z"
              transform="translate(-1.96 -1.74)"
            />
            <polygon
              class="cls-1"
              points="231.05 1.71 231.02 0.19 217.37 0.19 217.37 10.74 231.05 10.74 231.05 9.22 220.64 9.22 220.64 6.23 230.76 6.23 230.8 4.69 220.64 4.69 220.64 1.71 231.05 1.71"
            />
            <polygon
              class="cls-1"
              points="325.15 0.19 321.41 0.19 316.66 3.37 311.87 0.19 308.16 0.19 315.05 4.78 315.05 10.93 318.31 10.93 318.31 4.75 325.15 0.19"
            />
            <polygon
              class="cls-1"
              points="292.02 0.19 288.82 0.19 288.82 0.19 281.43 0.19 281.43 1.74 288.82 1.74 288.82 10.93 292.02 10.93 292.02 1.74 299.4 1.74 299.4 0.19 292.02 0.19 292.02 0.19"
            />
            <path
              class="cls-1"
              d="M204.69,6.67c2.07-.76,2.34-2,1.51-3s-2.43-1.73-5-1.73h-8.61V12.48h9.35c4,0,6-1.6,6-3.19C207.91,8.3,207.05,7.18,204.69,6.67Zm-9.17-3.29h5.65c3.25,0,3.19,2.74-.06,2.74h-5.59ZM201.91,11h-6.39V7.63h6.39c2,0,3,.85,3,1.68S203.92,11,201.91,11Z"
              transform="translate(-1.96 -1.74)"
            />
            <path
              class="cls-1"
              d="M256.18,8c-.54,1.5-2.59,2.62-5.05,2.62-2.87,0-5.2-1.52-5.2-3.39s2.33-3.38,5.2-3.38c2.46,0,4.51,1.12,5.05,2.61h3.25c-.58-2.65-4.07-4.69-8.3-4.69-4.64,0-8.4,2.44-8.4,5.46s3.76,5.47,8.4,5.47c4.23,0,7.72-2,8.3-4.7Z"
              transform="translate(-1.96 -1.74)"
            />
          </svg> -->
        <div class="copyright">
          <p>Copyright © R.C.I. All Rights Reserved.</p>
        </div>
      </div>
      <div class="sp-contact-overlay">
        <div class="mail-contact" v-scroll-to="'#contact'">
          <span>お問い合わせ</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LayoutFooter",
  components: {},
  props: {
    path: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.cls-1 {
  fill: white;
}
.tokuteisho {
  text-align: center;
  margin-bottom: 5px;
  a {
    text-decoration: none;
    color: black;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
.footer {
  .fotter-inner {
    background-color: var(--main);
    height: 150px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 20px 40px;
    @media screen and (max-width: 767px) {
      align-items: center;
      flex-direction: column;
    }
    .logo{
      width: 250px;
      height: auto;
      object-fit: cover;

      @media screen and (max-width: 767px) {
        width: 60%;
      }
    }
  }
  .copyright {
    font-size: 13px;
    color: white;
    // margin-right: 200px;
    @media screen and (max-width: 767px) {
      margin-right: 0;
      font-size: 10px;
    }
  }
  .sp-contact-overlay {
    display: none;
    @media screen and (max-width: 767px) {
      display: initial;
      display: flex;
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 56px;
      background-color: rgba(255, 255, 255, 0.9);
      align-items: center;
      justify-content: center;
      z-index: 999;
      .mail-contact {
        width: 60%;
        padding: 8px 0;
        border: 1px solid black;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          text-decoration: none;
          color: var(--sub);
          font-weight: 700;
          font-size: 18px;
        }
      }
    }
  }
}
.footer-sp-bottom {
  @media screen and (max-width: 767px) {
    margin-bottom: 50px;
  }
}
</style>
