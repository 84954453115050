<template>
  <div class="home">
    <div class="top">
      <img
        alt="RobecityInternationalトップ"
        src="@/assets/RobecityInternational-hp-top-001.jpg"
        class="pc-only"
      />
      <img
        alt="RobecityInternationalトップ"
        src="@/assets/RobecityInternational-hp-top-001sp.jpg"
        class="sp-only"
      />
    </div>

    <v-parallax
      src="@/assets/RobecityInternational-hp-top-002sp.jpg"
      height="600"
    >
      <HomeAboutUs />
    </v-parallax>
    <!-- <div class="sp-only">
      <HomeAboutUs />
    </div> -->

    <div style="background-color: var(--main)">
      <HomeService class="wrap padding-100" />
    </div>

    <div class="space-70" id="info">
      <div class="space-130">
        <HomeInformaition />
      </div>
    </div>

    <div class="space-70" id="recruit">
      <HomeRecruit class="space-130 space-100-bm" />
    </div>

    <div style="background-color: var(--sub2)">
      <HomeContact class="wrap" style="padding-bottom: 50px" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HomeAboutUs from "@/components/Home/AboutUs.vue";
import HomeService from "@/components/Home/Service.vue";
import HomeInformaition from "@/components/Home/Informaition.vue";
import HomeRecruit from "@/components/Home/Recruit.vue";
import HomeContact from "@/components/Home/Contact.vue";

export default {
  name: "Home",
  components: {
    HomeAboutUs,
    HomeService,
    HomeInformaition,
    HomeRecruit,
    HomeContact,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped>
.top{
  img{
    max-width: 100%;
  }
}
img {
  vertical-align: top;
}

.parallax-window {
  min-height: 800px;
  background: transparent;
}
</style>