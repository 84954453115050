var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui-box"},[_c('div',{class:{
      typeA: _vm.type === 'A',
      typeA2: _vm.type === 'A2',
      typeA3: _vm.type === 'A3',
      typeB: _vm.type === 'B',
      typeB2: _vm.type === 'B2',
      typeC: _vm.type === 'C',

    }},[_c('div',{staticClass:"cover"},[_c('h4',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.detail)}}),_c('UiTable',{attrs:{"tableList":_vm.tableList}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }