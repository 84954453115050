<template>
  <div class="ui-box">
    <div
      :class="{
        typeA: type === 'A',
        typeA2: type === 'A2',
        typeA3: type === 'A3',
        typeB: type === 'B',
        typeB2: type === 'B2',
        typeC: type === 'C',

      }"
    >
      <div class="cover">
        <h4 class="title">{{title}}</h4>
        <p v-html="detail"></p>
        <UiTable :tableList="tableList"/>
    </div>
  </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiTable from '../Ui/Table.vue';
export default {
  name: "UiBox",
  components: {
    UiTable,
  },
  props: {
    type: String,
    title: String,
    detail: String,
    tableList:Array,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.ui-box {
  .typeA{
    .cover{
      .title{
        background-color: var(--sub);
        color: white;
        padding: 10px 0;
        text-align: center;
        margin-bottom: 20px;
      }
      p{
        padding-bottom: 20px;
        min-height: 90px;
        padding-left: 5px;
        padding-right: 5px;
        @media screen and (min-width:768px) and ( max-width:1092px) {
          min-height: 126px;
        }
      }
    }
 
  }
 
}
</style>