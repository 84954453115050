<template>
  <v-app>
     <Header />
    <v-main>
      <router-view  class="body-top" />
          <ToTopButton :path="path" />
    </v-main>
    <Footer :path="path"/>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import "reset-css";
import Header from "@/components/Layout/Header.vue";
import ToTopButton from "@/components/Ui/ToTopButton.vue";
import Footer from "@/components/Layout/Footer.vue";


export default {
  name: 'App',
  // data: () => ({
  //   //
  // }),
    data() {
    return {};
  },
  components: {
    Header,
    Footer,
    ToTopButton,
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    path() {
      return this.$route.path;
    },
  },
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap');
#app {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 16px;
  // color: #4c4948;
}

:root {
  --main: #4c4948;
  --sub: #a69d4e;
  --sub2: #e9e3d8;
  --black:#4c4948;
}

html * {
  box-sizing: border-box !important;
}

img {
  // max-width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}

.wrap {
  width: 100vw; //画面幅に対して、基本100%
  max-width: 1100px; //でも、1150pxを超える画面幅でwidth: 100%では困るのでこの設定
  // width: 1150px;
  margin: 0 auto;
  line-height: 2.2em;

  @media screen and (max-width: 1100px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.sp-only {
  display: none;
  @media screen and (max-width: 767px) {
    display: initial !important;
  }
}

.pc-only {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.small {
  font-size: 13px;
}
.small-sub {
  @media screen and (max-width: 767px) {
    margin-left: 45px;
  }
}

.body-top {
  margin-top: 70px;
    @media screen and (max-width: 767px) {
     margin-top: 60px;
  }

}

h1,
h2 {
  font-size: 32px;
  font-weight: 500;
  color: var(--sub);
  // text-align: center;
}
h3 {
  font-size: 22px;
}
h4 {
  font-size: 18px;
}
.v-application p{
  margin-bottom: 0!important;
}
a{
  text-decoration: none;
}

.space-70 {
  margin-top: 70px;
  @media screen and (max-width: 767px) {
    margin-top: 60px;
  }
}
.space-50 {
  margin-top: 50px;
  @media screen and (max-width: 767px) {
    margin-top: 15px;
  }
}
.space-50-bm {
  margin-bottom: 50px;
  @media screen and (max-width: 767px) {
    margin-bottom: 15px;
  }
}
.space-100-bm {
  margin-bottom: 100px;
  @media screen and (max-width: 767px) {
    margin-bottom: 30px;
  }
}
.space-100 {
  margin-top: 100px;
  @media screen and (max-width: 767px) {
    margin-top: 30px;
  }
}
.space-150 {
  margin-top: 150px;
  @media screen and (max-width: 767px) {
    margin-top: 60px;
  }
}
.space-150-bm {
  margin-bottom: 150px;
  @media screen and (max-width: 767px) {
    margin-bottom: 60px;
  }
}
.space-130 {
  margin-top: 130px;
  @media screen and (max-width: 767px) {
    margin-top: 50px;
  }
}
.space-200 {
  margin-top: 200px;
  @media screen and (max-width: 767px) {
    margin-top: 100px;
  }
}
.padding-200{
  padding: 200px 0;
    @media screen and (max-width: 767px) {
     padding: 100px 0;
  }
}




</style>
