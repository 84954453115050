import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    rootUrl: 'https://robe-international.com',
  },
  getters: {
    rootUrl(state) {
      return state.rootUrl
    },
  },
  mutations: {
    setRootUrl(state, payload) {
      state.rootUrl = payload;
    },
  },
  actions: {
    setRootUrl({
      commit
    }) {
      let rt = process.env.NODE_ENV === 'production' ? "https://robe-international.com" : "http://robe-international.localhost";
      //↑同じ意味↓
      // let rt;
      // if(process.env.NODE_ENV === 'production') {
      //   rt = "https://aru-dry.com";
      // }else {
      //   rt = "http://aru-dry.localhost";
      // }
      commit('setRootUrl', rt);
    },
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
