<template>
  <div class="home-service space-50" style="padding-top:100px;">
        <UiHeading
          :h="'2'"
          :type="'E'"
          :text="'Contact'"
          :subTitle="'お問い合わせ'"
           id="contact" 
        />
        
        <ContactContactForm/>

       
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import ContactContactForm from "@/components/Contact/ContactForm.vue";

export default {
  name: "HomeService",
  components: {
    UiHeading,
    ContactContactForm
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>

</style>
