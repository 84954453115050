<template>
  <div class="home-service" style="padding-top:100px;" >
        <UiHeading
          :h="'1'"
          :type="'E2'"
          :text="'Services'"
          :subTitle="'事業内容'"
          id="service"
        />

        <div class="box-cover">
          <UiBox :imgPath="'RobecityInternational-hp-top-007.jpg'" :type="'A2'" :imgName="'テスト'" :linkPath="'https://www.rci85.com/'" :text="'「自動車陸送の業界最安値に挑戦」をモットーに法人・個人向けの自動車陸送を行っています。'" class="box" :boxTitle="`自動車の陸送事業`"/>
          <UiBox :imgPath="'RobecityInternational-hp-top-003.jpg'" :type="'A3'" :imgName="'テスト'" :text="'中古自動車（国産・外国産）の買取と販売を行っています。お客様のご希望にそった対応と、高価買取とお値打ち価格での販売が強みです。'" class="box space-50-bm" :boxTitle="`自動車の販売事業`"/>
          <UiBox :imgPath="'RobecityInternational-hp-top-004.jpg'" :type="'A3'" :imgName="'テスト'" :text="'高級ワインのネットでの小売、店舗やホームパーティへのワインソムリエの派遣サービスを行っています。ワインのある豊かな生活をサポートしています。'" class="box space-50-bm" :boxTitle="`アルコール類の販売事業`"/>
          <UiBox :imgPath="'RobecityInternational-hp-top-005.jpg'" :type="'A2'" :imgName="'テスト'" :linkPath="'http://www.tanning-hotdog.jp/'" :text="'名古屋栄のテレビ塔のそばで、日焼けサロンHotDogの運営を行っています。短時間で小麦色の肌になれるマシーンを揃えています。'" class="box" :boxTitle="`日焼けサロン事業`"/>
        </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiBox from '../Ui/Box.vue';

export default {
  name: "HomeService",
  components: {
    UiHeading,
     UiBox,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.home-service{
  background-color: var(--main);
  ::v-deep p{
    color: var(--sub2)!important;
  }
}
.box-cover{
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
.box{
  width: 48%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
}
</style>
