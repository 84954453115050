import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import {
  setTitle,
  setDescription
} from '@/mixins/index.js'


import Home from '../views/Home.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    // redirect: '/fl/',
    meta: {
      title: '株式会社R.C.I.（アールシーアイ）',
      description: '株式会社R.C.I.（アールシーアイ、旧社名：株式会社Robecity International（ローブシティインターナショナル））は、中古自動車の販売、アルコール類の販売とワインソムリエの派遣サービス、名古屋市中区での日焼けサロンHotDogの運営などを行っています。'
    }
  },

  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition || to.meta.savedPosition == true) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  setTitle(to.meta.title);
  setDescription(to.meta.description);
  store.dispatch("setRootUrl");

  next();
  });

export default router
