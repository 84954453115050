import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({//kokoniメインカラーを設定
    theme: {
        themes: {
            light: {
                main: '#4c4948',
                sub: 'a69d4e',
                sub2: 'e9e3d8'
            },
        },
    },
    options: {
        customProperties: true
    }
});
