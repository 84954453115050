<template>
  <div class="home-service">
        <div class="wrap">
        <UiHeading
          :h="'2'"
          :type="'E'"
          :text="'Recruit'"
          :subTitle="'求人・採用'"
        />

        <p class="des">株式会社R.C.I.は、一緒にお仕事をする仲間を募集しています。年間を通して、既存の事業を育てながら、新規事業を1～2つのペースで立ち上げる成長中の企業です。そのため、責任あるポジションでの仕事を通して、キャリアアップを目指すことができます。</p>

<div class="box-cover">
        <UiBox2 :type="'A'" :title="`自動車の販売事業の統括`" :tableList="TableList" :detail="`自動車販売事業の営業・管理など統括としての仕事をしていただきます。事業のトップとしてお仕事をすることができるポジションです。`" class="box"/>
        <UiBox2 :type="'A'" :title="`事務`" :tableList="TableList2" :detail="`経理や管理、雑務などの事務のお仕事をしていただきます。`" class="box"/>
</div>
<p>給与・休日は、これまで経験・スキル・ご希望をもとに、入社時に決定します。統括系のお仕事は、勤務評価によって事業を分割しての独立など、キャリアアップをのぞむことができます。詳細はお問い合わせくださいませ。</p>

       </div>
<h4 class="toiawase">求人へのご応募・お問い合わせは、お電話、または、お問い合わせフォームからお願いいたします。</h4>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiBox2 from '../Ui/Box2.vue';

export default {
  name: "HomeService",
  components: {
    UiHeading,
     UiBox2,
  },
  props: {},
  data() {
    return {
      TableList: [
        {
          id: 1,
          th: "雇用形態",
          td: "パートタイム・正社員",
        },
        {
          id: 2,
          th: "勤務地",
          td: "〒501-6244<br>岐阜県羽島市竹鼻町丸の内１１丁目５２番地、他",
        },],
      TableList2: [
        {
          id: 1,
          th: "雇用形態",
          td: "パートタイム",
        },
        {
          id: 2,
          th: "勤務地",
          td: "〒501-6244<br>岐阜県羽島市竹鼻町丸の内１１丁目５２番地",
        },]
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.des{
  padding: 50px 0;
}
.box-cover{
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
.box{
  width: 48%;
  margin-bottom: 30px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
}
.toiawase{
  background-color: var(--main);
  color: white;
  text-align: center;
  padding: 30px 0;
  margin-top: 50px;
  @media screen and (max-width: 1100px) {
padding-right: 20px;
padding-left: 20px;
  }
}

</style>
