<template>
  <div class="cotacnt-form space-50">
    <div class="cover">
      <div class="title">
        <div v-if="pageType === 1">
          <h3>
            入力内容にお間違いがないかご確認の上、「送信」ボタンを押して下さい。
          </h3>
        </div>
      </div>

      <div class="form" v-if="pageType === 0 || pageType === 1">
        <v-form ref="form" v-model="valid" lazy-validation>
          <!-- 会社名 -->
          <div v-if="pageType === 0">
            <v-text-field
              v-model="form.companyName"
              :counter="20"
              label="法人名・屋号名"
              required
              color="sub"
            ></v-text-field>
          </div>
          <div v-else-if="pageType === 1" class="confirmation company">
            <p class="box-title">法人名・屋号名</p>
            <p class="input-box company">{{ form.companyName }}</p>
          </div>

          <!-- 名前（必須） -->
          <div v-if="pageType === 0">
            <v-text-field
              v-model="form.name"
              :counter="10"
              :rules="nameRules"
              label="名前（必須）"
              required
              color="sub"
            ></v-text-field>
          </div>
          <div v-else-if="pageType === 1" class="confirmation">
            <p class="box-title">お名前（必須）</p>
            <p class="input-box">{{ form.name }}</p>
          </div>

          <!-- メールアドレス -->
          <div v-if="pageType === 0">
            <v-text-field
              v-model="form.mail"
              :rules="emailRules"
              label="返信先メールアドレス（必須）"
              required
              color="sub"
            ></v-text-field>
          </div>
          <div v-else-if="pageType === 1" class="confirmation">
            <p class="box-title">メールアドレス（必須）</p>
            <p class="input-box">{{ form.mail }}</p>
          </div>

          <!-- お問い合わせ区分 -->
          <div v-if="pageType === 0">
            <v-radio-group v-model="form.type" :rules="selectRules" label="お問い合わせ内容（必須）" style="border-bottom:1px solid gray;margin-bottom:25px;">
              <v-radio label="自動車の陸送事業" :value="'4'" color="sub"></v-radio>
              <v-radio label="自動車の販売事業" :value="'0'" color="sub"></v-radio>
              <v-radio label="アルコール類の販売事業" :value="'1'" color="sub"></v-radio>
              <v-radio label="日焼けサロン事業" :value="'2'" color="sub"></v-radio>
              <v-radio label="求人" :value="'3'" color="sub"></v-radio>
              <v-radio label="その他" :value="'9'" color="sub"></v-radio>
            </v-radio-group>
          </div>
          <div v-else-if="pageType === 1" class="confirmation">
            <p class="box-title">お問い合わせ区分 （必須）</p>
            <p class="input-box">
              <span v-show="form.type === '0'">自動車の販売事業</span>
              <span v-show="form.type === '1'">アルコール類の販売事業</span>
              <span v-show="form.type === '2'">日焼けサロン事業</span>
              <span v-show="form.type === '3'">求人</span>
              <span v-show="form.type === '4'">自動車の陸送事業</span>
              <span v-show="form.type === '9'">その他</span>
            </p>
          </div>

          <!-- お問い合わせ内容 -->
          <div v-if="pageType === 0">
            <v-textarea
              color="sub"
              label="お問い合わせ内容詳細（必須）"
              v-model="form.description"
              :rules="textareaRules"
            ></v-textarea>
          </div>
          <div v-else-if="pageType === 1" class="confirmation">
            <p class="box-title">お問い合わせ内容（必須）</p>
            <p class="input-box">{{ form.description }}</p>
          </div>

          <!-- 確認ボタン -->
          <div
            style="display: flex; justify-content: center; margin-top: 30px"
            v-if="pageType === 0"
          >
            <v-btn
              :disabled="!valid"
              color="sub"
              @click="validate"
              large
            >
              確認する
            </v-btn>
          </div>
          <!-- <div><p>確認する</p></div>
          </div> -->
          <div class="soushin" v-if="pageType === 1">
            <v-btn color="main" class="mr-4 white--text" @click="back" large>修正</v-btn>
            <v-btn color="sub" @click="send" large>送信</v-btn>
          </div>
        </v-form>
      </div>
      <!-- 送信後のページ -->
      <div v-else-if="pageType === 2" class="complete">
        <h2 style="margin-bottom: 50px">お問い合わせを承りました</h2>
        <p>
          お問い合わせ内容を、ご入力のメールアドレスに送信しました。<br />20分経過しても届かない場合は、迷惑メールボックスの中をご確認ください。
        </p>
       
           <v-btn color="sub" large @click="reset">トップに戻る</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import Table from "../Ui/Table.vue";
export default {
  name: "CotacntForm",
  // components: Table,
  props: {
    type: Number,
  },
  data() {
    return {
      pageType: 0,
      form: {
        companyName: "",
        name: "",
        // tel: "",
        mail: "",
        type: "",
        description: "",
      },
      row: null,
      valid: true,
      nameRules: [(value) => !!value || "必須項目です"],
      emailRules: [
        (value) => value === value || !!value || "必須項目です",
        (value) => (value && value.length >= 3) || "3文字以上入力してください",
        (value) =>
          (value.indexOf("hotmail.co.jp") === -1 &&
            value.indexOf("outlook.jp") === -1) ||
          "hotmail. outlookは使えません",
      ],
      selectRules: [(value) => !!value || "必須項目です"],
      textareaRules: [(value) => !!value || "必須項目です"],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  created() {},
  methods: {
    validate() {
      if (this.$refs.form.validate() === true) {
        this.pageType = 1;
      } else {
        return;
      }
    },
    send() {
      let params = new URLSearchParams();
      params.append("company_name", this.form.companyName);
      params.append("name", this.form.name);
      // params.append("tel", this.form.tel);
      params.append("mail", this.form.mail);
      params.append("type", this.form.type);
      // params.append("bottle1_num", this.form.bottle1Num);
      // params.append("bottle2_num", this.form.bottle2Num);
      // params.append("bottle3_num", this.form.bottle3Num);
      params.append("description", this.form.description);
      this.$axios
        .post(`${this.rootUrl}/api/send.php`, params)
        .then((response) => {
          console.log(response);
          alert(response.data.message);
          if (response.data.status === 200) {
            // console.log('ok')
            this.pageType = 2;
            return;
          } else if (
            response.data.status === 400 ||
            response.data.status === 500
          ) {
            alert(response.data.message);
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    back() {
      // alert("本当に戻りますか？");
      this.pageType = 0;
    },
    reset () {
      // this.$refs.form.reset();
      this.form.companyName = '';
      this.form.name = '';
      this.form.mail = '';
      this.form.type = '';
      this.form.description = '';
      this.pageType = 0;
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.cotacnt-form {
  background-color: white;
  padding-bottom: 30px;
  padding-top: 50px;
  @media screen and (max-width: 1100px) {
    padding-right: 20px;
    padding-left: 20px;
  }
}
.cover {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 767px) {
    width: 100%;
  }

  .title {
    // padding-bottom: 30px;
    padding-left: 0;

    @media screen and (max-width: 767px) {
      padding-bottom: 5px;
    }

    h3 {
      padding-top: 50px;
      text-align: center;
      font-size: 16px;
      @media screen and (max-width: 767px) {
        padding-top: 30px;
      }
    }
  }

  .form {
    margin-bottom: 50px;
    // margin-top: 30px;

    .confirmation{
      .box-title{
        color: var(--sub);

      }
      .input-box{
        padding-bottom: 5px;
        padding-left: 5px;
        border-bottom: 1px solid var(--main);
      }
      .company{
        min-height: 40px;
      }
    }
  }

  .soushin {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
    // width: 50%;
    .button {
      width: 150px;
      @media screen and (max-width: 767px) {
      }
      width: 40%;
    }
  }

  textarea {
    height: 120px;
    margin-top: 10px;
    text-rendering: auto;

    @media screen and (max-width: 767px) {
      margin: 0;
    }
  }
  //商品購入のとき増えるtr
  .buy,
  .buy2 {
    width: 80%;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
  }
  .buy {
    @media screen and (max-width: 1023px) {
      display: block;
      p {
        margin-bottom: 10px;
      }
    }
  }

  .sp {
    margin-left: 20px;
    @media screen and (max-width: 767px) {
      display: block;
    }
  }

  .complete {
    text-align: center;
    h2 {
      @media screen and (max-width: 767px) {
        text-align: left;
        font-size: 25px;
      }
    }
    a {
      text-decoration: none;
      color: black;
      
    }
    p {
      text-align: center;
      padding-bottom: 50px;
      @media screen and (max-width: 767px) {
        text-align: left;
      }
    }
  }
}
.about-item {
  ul {
    li {
      font-size: 20px;
      margin-top: 30px;
    }
  }
  .detail {
    padding-left: 1em;
  }
}
.confirmation {
  padding: 20px;
}
</style>