<template>
  <div class="home-about-us">
      <UiHeading
        :h="'1'"
        :type="'E'"
        :text="'About R.C.I.'"
        :subTitle="'会社概要'"
      />
      <p style="margin-top: 50px; text-align: left">
        株式会社R.C.I.（アールシーアイ）は、中古自動車の販売、ワインを代表するアルコール類の販売とワインソムリエの派遣サービス、名古屋栄のテレビ塔そばで日焼けサロンHotDogの運営などを行っています。お客様のご希望にそった柔軟な対応を得意とし、お客様満足を第一に考えたサービスを提供しています。
      </p>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
// import UiBox from '../Ui/Box.vue';

export default {
  name: "HomeAboutUs",
  components: {
    UiHeading,
    //  UiBox,
  },
  props: {},
  data() {
    return {
      // src: "",
      // height: "",
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  // created() {
  //   //
  //   if (window.matchMedia("(min-width:768px)").matches) {
  //     this.src = "RobecityInternational-hp-top-002sp.jpg";
  //     this.height = "600";
  //   } else if (window.matchMedia("(max-width: 767px)").matches) {
  //     this.src = "RobecityInternational-hp-top-002sp-1079.jpg";
  //     this.height = "600";
  //   } else {
  //     //no action.
  //   }
  // },
};
</script>

<style lang="scss" scoped>
.home-about-us {
  max-width: 1100px;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    padding-top: 100px;
    padding-bottom: 100px;
    padding-right: 20px;
    padding-left: 20px;
  }

  p {
    color: var(--black) !important;
  }
}
</style>
